#ys360j-wrapper {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
}

.ys360-size {
  width: 800px;
  @apply bg-contain;
}

#Layer_1 {
  width: 800px;
  @apply m-0;
}

.sector {
  // fill: #1ccf64;
  @apply cursor-pointer opacity-60 fill-current text-primary hover:text-blue-500;
}

.hide {
  @apply hidden opacity-0;
}

.tooltip {
  @apply fixed p-2 rounded text-white bg-gray-800 bg-opacity-90 ring ring-offset-2 ring-gray-800;
}

.tooltipTitle {
  @apply block text-base uppercase;
}

.tooltipSubTitle {
  @apply block text-sm italic;
}

.tooltipFinger {
  background-image: url("https://ys360j.jugaad.digital/assets/finger_white.png");
  @apply m-auto bg-contain w-8 h-8;
}
